<template>
  <div id="codes-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Promocode History </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <v-row class="px-2 ma-0">
        <!--plan filter-->
        <v-col cols="4">
          <v-select
            v-model="planFilter"
            placeholder="Select Criteria"
            label="Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="searchQuery"
              :placeholder="`Search By ${planFilter ? planFilter : ''}`"
              label="Search"
              outlined
              hide-details
              dense
              class="events-search me-3 mb-4"
            >
            </v-text-field>
          </v-card-text>
        </v-col>
      </v-row>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="codesListTable"
        :options.sync="options"
        :server-items-length="totalCodesListTable"
        :loading="loading"
      >
        <!-- iD  -->
        <template #[`item.id`]="{ item }">
          <div class="d-flex">PC{{ item.id }}</div>
        </template>

        <!-- start_date  -->
        <template #[`item.start_date`]="{ item }">
          <div class="d-flex">
            {{ moment(item.start_date) }}
          </div>
        </template>

        <!-- end_date  -->
        <template #[`item.end_date`]="{ item }">
          <div class="d-flex">
            {{ moment(item.end_date) }}
          </div>
        </template>

        <!-- status  -->
        <template #[`item.status`]="{ item }">
          <div class="d-flex">
            <v-chip
              small
              class="font-weight-semibold text-capitalize text-center"
              :color="resolveCodeStatusVariant(item.status)"
            >
              {{ item.status ? 'Enabled' : 'Disabled' }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-code-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import CodesStoreModule from '../CodesStoreModule'
import useCodesList from './useCodeHistoryList'
import moment from 'moment'

export default {
  methods: {
    moment: date => {
      return moment(date).locale('JO').format('DD MM YYYY [@] HH:mm')
    },
  },
  setup(props, { emit }) {
    const CODES_APP_STORE_MODULE_NAME = 'app-codes'
    const status = ref(false)
    const { resolveCodeStatusVariant } = useCodesList()

    // Register module
    if (!store.hasModule(CODES_APP_STORE_MODULE_NAME)) {
      store.registerModule(CODES_APP_STORE_MODULE_NAME, CodesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(CODES_APP_STORE_MODULE_NAME)
    })
    const {
      codesListTable,
      tableColumns,
      searchQuery,
      totalCodesListTable,
      loading,
      options,
      codesTotalLocal,
      selectedRows,
      planFilter,
      fetchCodes,
    } = useCodesList()

    const planOptions = [
      { title: 'Date', value: 'Date' },
      { title: 'Title', value: 'Title' },
    ]

    return {
      resolveCodeStatusVariant,
      codesListTable,
      tableColumns,
      searchQuery,
      totalCodesListTable,
      loading,
      options,
      codesTotalLocal,
      selectedRows,
      planOptions,
      planFilter,
      fetchCodes,
      status,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.dialog_message {
  font-size: large;
}
</style>
