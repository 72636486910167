import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users')
          .then(response => {
            resolve(response.data.data.filteredData)
          })
          .catch(error => reject(error))
      })
    },

    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories')
          .then(response => {
            resolve(response.data.data.filteredData)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCompanies(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/companies')
          .then(response => resolve(response.data.data.filteredData))
          .catch(error => reject(error))
      })
    },
    fetchSubCategories(ctx, parentId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/categories?options={"parent_id":"${parentId}"}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchItems(ctx, parentId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/items?options={"subcategory_id":"${parentId}"}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchEmployees(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/company-employees?options={"company_id":${id}}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/promocodes', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchHistoryCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/promocodes', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCode(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/promocodes/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCode(ctx, data) {
      const bodyFormData = new FormData()

      if (data.title) bodyFormData.append('title', data.title)
      if (data.description_en) bodyFormData.append('description_en', data.description_en)
      if (data.description_ar) bodyFormData.append('description_ar', data.description_ar)
      if (data.covered_services) bodyFormData.append('covered_services', data.covered_services)
      if (data.promocode_type) bodyFormData.append('promocode_type', data.promocode_type)
      if (data.offer) bodyFormData.append('offer', data.offer)
      if (data.beneficiaries) bodyFormData.append('beneficiaries', data.beneficiaries)
      if (data.minimum_purchase_value) bodyFormData.append('minimum_purchase_value', data.minimum_purchase_value)
      if (data.maximum_applied_value) bodyFormData.append('maximum_applied_value', data.maximum_applied_value)
      if (data.usage_limit) bodyFormData.append('usage_limit', data.usage_limit)
      if (data.usage_limits_per_user) bodyFormData.append('usage_limits_per_user', data.usage_limits_per_user)
      if (data.start_date) bodyFormData.append('start_date', data.start_date)
      if (data.end_date) bodyFormData.append('end_date', data.end_date)
      if (data.category_id) bodyFormData.append('category_id', data.category_id)
      if (data.company_id) bodyFormData.append('company_id', data.company_id)
      if (data.chefs) {
        data.chefs.forEach((ele, idx) => {
          bodyFormData.append(`chefs[${idx}]`, ele)
        })
      }
      if (data.status) bodyFormData.append('status', data.status)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/promocodes/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    editCode(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('promocode_id', data.id)
      if (data.items) bodyFormData.append('items', JSON.stringify(data.items))
      if (data.subcategories) bodyFormData.append('subcategories', JSON.stringify(data.subcategories))
      if (data.category) bodyFormData.append('category', data.category)
      if (data.company) bodyFormData.append('company', JSON.stringify(data.company))
      if (data.for_all_users) {
        bodyFormData.append('all', 1)
      } else {
        bodyFormData.append('all', 0)
      }
      if (data.freeDelivery) {
        bodyFormData.append('is_free_delivery', 1)
      } else {
        bodyFormData.append('is_free_delivery', 0)
      }
      if (data.users && !data.for_all_users) bodyFormData.append('users', JSON.stringify(data.users))
      bodyFormData.append('offer', data.offer)
      bodyFormData.append('code', data.code)
      bodyFormData.append('start_date', data.start_date)
      bodyFormData.append('end_date', data.end_date)
      bodyFormData.append('title_en', data.title_en)
      bodyFormData.append('title_ar', data.title_ar)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/promocodes/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    changeStatus(_ctx, _id) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData()
        bodyFormData.append('promocode_id', _id)

        axios({
          method: 'post',
          url: '/promocodes/change-status',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    fetchKitchens(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/kitchens', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChefs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/chiefs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
