import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCodeHistoryList() {
  const codesListTable = ref([])

  const tableColumns = [
    { text: '#ID', value: 'id' },
    { text: 'PromoCode Title', value: 'title' },
    { text: 'Discount value', value: 'offer' },
    { text: 'Start Date & Time', value: 'start_date' },
    { text: 'End Date & Time', value: 'end_date' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const totalCodesListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    q:searchQuery.value
  })
  const codesTotalLocal = ref([])
  const selectedRows = ref([])



  const fetchHistoryCodes = () => {
    
    let params = {
      filter: 'history',
      options: options.value,
    }
    params.page = options.value.page

    if (planFilter.value) {
      if (planFilter.value == 'Title') params.title = searchQuery.value
      if (planFilter.value == 'Date') params.start_date = searchQuery.value
    }

    store
      .dispatch('app-codes/fetchHistoryCodes',params)
      .then(response => {
        const data = response.data.data
        codesListTable.value = data.data
        totalCodesListTable.value = data.total
        codesTotalLocal.value = data.total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, planFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchHistoryCodes()
  })

  return {
    codesListTable,
    planFilter,
    searchQuery,
    tableColumns,
    totalCodesListTable,
    loading,
    options,
    codesTotalLocal,
    selectedRows,
    fetchHistoryCodes
  }
}
